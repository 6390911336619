@import '_colors';

.find-component {
    display: flex;
    padding: 4px 10px 4px 12px;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    height: 28px;
    gap: 4px;

    border-radius: 8px;
    border: 1px solid $color-gray-400;

    input {
        border: none;
        height: 28px;
        background-color: transparent;
    }
    *:focus {
        outline: none;
    }

}

@import '_colors';

.filters {

    color: $color-menu-text;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    
    /* Text sm/Medium */
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */    
    position: relative;

    &-label {
        display: flex;
        padding: 4px 10px 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid $color-gray-400;
        height: 28px;
    }

    &-dropdown {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
    }

    &-active {
        color: $color-filters-active-text;
        background: $color-filters-active-background;
        border: 1px solid $color-filters-active-border;
    }

    *:focus {
        outline: none;
    }

    &-tag-panel {
        display: flex;
        padding: 10px 16px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }

    &-tag-list {
        display: flex;
        padding: 6px 12px;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: wrap;      
        
        min-width: 265px;
        min-height: 38px;

        &.borders {
            
            border-radius: 4px;
            border: 1px solid $color-gray-400;
            background: $color-menu-bg;}

        &-item {
            display: flex;
            padding: 2px 6px 2px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 8px;        
            background: #ECE0DB;
            color: $color-main-text;
        }

    }

    &-b-success {
        background-color: #DEECDC;
    }
    &-b-important {
        background-color: #FEE4E2;
    }
    &-b-postponed {
        background-color: #EAECF5;
    }
    &-b-gray {
        background-color: #F2F4F7;
    }

    &-b-plan {
        background-color: #FCE7F6;
    }
    &-b-prepare {
        background-color: #EBE9FE;
    }
    &-b-execute {
        background-color: #F5DFCC;
    }

}
.width-80 {
    width: 80px;
}
.width-90 {
    width: 90px;
}
.width-100 {
    width: 100px;
}
.width-120 {
    width: 120px;
}
.width-150 {
    width: 150px;
}
.width-180 {
    width: 180px;
}

.width-200 {
    width: 200px;
}
.width-250 {
    width: 250px;
}
.width-300 {
    width: 300px;
}
.width-350 {
    width: 350px;
}

.width-400 {
    width: 400px;
}

.width-450 {
    width: 450px;
}


.h-28 {
    height: 28px;
}
.h-38 {
    height: 38px;
}
.height-100 {
    height: 100px !important;
}
.height-200 {
    height: 200px !important;
}
.height-300 {
    height: 300px !important;
}
.height-350 {
    height: 350px !important;
}

.height-400 {
    height: 400px !important;
}


@import '_colors';

.calls-panel {

    position: absolute;
    left: 0;
    bottom: 0;    

    display: flex;
    width: 280px;
    padding: 10px 24px 10px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.calls-card {

    display: flex;
    padding: 12px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: 8px;
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(12px);

    .company {
        display: flex;
        padding: 0px 4px;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        align-self: stretch;      
        
        .img-call {
            display: flex;
            padding: 8px 8px 6px 6px;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            background: rgba(255, 255, 255, 0.20);
        }
        .title {
            color: rgba(255, 255, 255, 0.75);
            font-feature-settings: 'ss01' on, 'salt' on;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */                
        }
        .duration {
            color: rgba(255, 255, 255, 0.30);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */            
        }
    }

    .contact {

        display: flex;
        padding-left: 11px;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        align-self: stretch;

        .contact-row {
            display: flex;
            width: 215px;
            align-items: flex-start;
            gap: 19px;    
            
            .title {
                color: rgba(255, 255, 255, 0.95);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 125% */
            }
        }
        .phone-row {
            display: flex;
            padding-left: 31px;
            align-items: center;
            gap: 6px;
            align-self: stretch;     
            
            color: rgba(255, 255, 255, 0.55);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }
    }
}
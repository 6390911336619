@import '_colors';
@import '_helpers';
@import '_elements';
@import '_sizes';
@import '_entity-card';
@import '_dropdown';
@import '_buttons';
@import '_color-circle';
@import '_find-component';
@import '_filters';
@import '_icons';
@import '_dashboard';
@import '_notifications';
@import '_calls';
@import '_paginations';

body {
    background: linear-gradient(27deg, #1d2939 0%, #344054 100%);
    font-family: 'Raleway' !important;
    color: #37352f;
    font-variant-numeric: lining-nums proportional-nums !important;
}

.cursor-pointer {
    cursor: pointer;
}

.my-12px {
    margin-top: 12px;
    margin-bottom: 12px;
}
.mt-12px {
    margin-top: 12px;
}

.color-main-text {
    color: $color-main-text !important;
}

.color-medium-dark {
    color: $color-medium-dark !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.max-width-150 {
    max-width: 150px;
}

.opacity-7 {
    opacity: 0.7;
}

span.span-flex-0 {
    flex: 0 !important;
}

.hover-show-parent {

    &:hover .hover-show{
        visibility: visible;
    }
    
    .hover-show {
        visibility: hidden;
    }
}

.modal.show {
    display: block;
}

.login-card {
    border-radius: 8px 8px 0px 0px;
    background: #fff;
    height: 100%;
    padding: 32px;
}

.btn {
    &-primary {
        background-color: $color-primary;
        border-color: $color-primary;

        &:hover {
            background-color: #195291;
        }
    }
}

// иконки берем черные и выбираем цвета на https://change-svg-color.vercel.app/
.icon-main {
    filter: invert(20%) sepia(10%) saturate(437%) hue-rotate(8deg)
        brightness(94%) contrast(96%);
}
.icon-active {
    filter: invert(29%) sepia(73%) saturate(1239%) hue-rotate(353deg)
        brightness(96%) contrast(102%);
}
.icon-gray {
    filter: invert(45%) sepia(1%) saturate(2378%) hue-rotate(6deg)
        brightness(96%) contrast(81%);
}
.icon-black {
    filter: invert(29%) sepia(7%) saturate(291%) hue-rotate(2deg)
        brightness(103%) contrast(90%);
}
.icon-nav {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(1deg)
        brightness(106%) contrast(101%);
    opacity: 0.85;
}
.icon-green {
    filter: invert(61%) sepia(57%) saturate(4967%) hue-rotate(118deg)
        brightness(98%) contrast(86%);
}
.icon-red {
    filter: invert(58%) sepia(74%) saturate(7172%) hue-rotate(343deg)
        brightness(99%) contrast(89%);
}

.input-check {
    position: absolute;
    top: 4px;
    right: 4px;
}

.form-control-label-into {
    position: relative;

    .form-label {
        position: absolute;
        top: 3px;
        left: 11px;
        color: #7a7a7a;
        font-size: 13px;
    }

    .form-control,
    .form-select {
        padding-top: 20px;
    }
}

.tr-detail-last {
    border-bottom: 2px solid red;
}

.skeleton {
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-td-inside {
    width: 100%;
    height: 1rem;
}

.skeleton-text {
    width: 100%;
    height: 1.5rem;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.page-size-block .btn {
    padding: 8px 10px !important;
    font-size: 14px;
    font-weight: 600;
    min-width: 40px;
    background-color: white;

    &:hover {
        color: black;
    }
}

.message-other {
    background-color: #f9fafb;
    border: 1px solid #d0d5dd;
    border-radius: 0 8px 8px 8px;
}

.login-left-block {
    padding-top: 20px;
    padding-left: 50px;

    .login-left-content {
        padding: 30px;
        color: #fff;

        img {
            padding-bottom: 40px;
        }
    }
}

.forgot-password a {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: $color-gray;
    text-decoration: none;
}

.about-company-block {
    font-size: 14px;
    font-weight: 500;
    color: $color-gray;
}

.show-password {
    top: 36px;
    right: 14px;
}

#sidebar {
    width: 264px;
    color: rgba(255, 255, 255, 0.85);
    padding-top: 22px;

    .sidebar-header {
        padding: 0 8px;
        margin-bottom: 8px;

        .user-block {
            cursor: pointer;
            border-radius: 4px;
            padding: 4px 6px 4px 16px;

            &.active {
                background: rgba(196, 196, 196, 0.3);
            }

            &:hover {
                background: rgba(196, 196, 196, 0.2);
            }

            .header-line {
                font-weight: 700;
                font-size: 14px;
            }

            .header-status {
                font-weight: 500;
                font-size: 12px;

                .indicator {
                    display: inline-block;
                    width: 11px;
                    height: 11px;
                    border-radius: 6px;
                    border: 1.5px solid #fff;

                    &.online {
                        background: $color-success-500;
                    }
                    
                    &.offline {
                        background: $color-gray-500;
                    }

                    &.busy {
                        background: $color-warning-500;
                    }
                }
            }
        }

        .user-menu {
            z-index: 5;
            margin-top: 5px;
            padding: 8px 0 6px 0;
            border-radius: 4px;
            background: #fff;
            color: $color-medium-dark;
            position: absolute;
            width: 320px;
            border: 1px solid $color-gray-400;
            box-shadow: 0px 14px 18px -2px rgba(16, 24, 40, 0.12);

            .header {
                padding: 8px 16px 14px 16px;
                font-size: 14px;
                font-weight: 600;
                color: $color-gray-main;
                border-bottom: 1px solid #d9d9d9;
            }

            .redirection {
                padding: 4px 16px 6px 16px;
                font-size: 14px;
                font-weight: 600;
            }

            .redirectObject {
                padding: 8px 22px 10px 32px;
                font-size: 14px;
                font-weight: 600;
                color: $color-menu-text;
            }

            .footer {
                padding: 10px 32px 4px 16px;
                border-top: 1px solid #d9d9d9;

                a {
                    font-weight: 600;
                    font-size: 14px;
                    color: $color-medium-dark;
                    text-decoration: none;
                }
            }

            .find-block {
                padding: 6px 16px
            }

            .employees-block {
                padding: 8px 16px 0 16px;
                font-size: 14px;
                font-weight: 600;

                .redirect-employee{
                    padding: 4px 0;

                    .employee-number{
                        width: 50px;
                        font-size: 12px;
                        font-weight: 700;
                    }}
            }
        }
    }

    .navbar-nav {
        padding: 8px;

        .nav-item {
            border-radius: 4px;
            padding: 4px 16px;
            margin-bottom: 2px;

            &.active {
                background: rgba(196, 196, 196, 0.3);
            }

            &:hover {
                background: rgba(196, 196, 196, 0.2);
            }

            .nav-link {
                padding: 0;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}

.content-container {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
    height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;

    .content-header {
        display: flex;
        padding: 7px 32px 7px 6px;
        border-bottom: 1px solid $color-gray-200;
        margin-bottom: 47px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        gap: 6px;
        position: relative;

        .header-left {
            padding: 4px;
        }

        .breadcrumbs {
            display: flex;
            padding: 6px;
            align-items: center;
        }

        
    }
}

.breadcrumbs-link a {
    color: #37352f;
    text-decoration: none;
    color: var(--vats-main-text, #37352f);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.page-title {
    color: '#37352F' !important;
    font-family: 'Raleway' !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 38px; /* 118.75% */
}

.content-table {
    height: 34px;
    padding: 12px 8px !important;
    font-size: 14px;
    font-style: normal;
    font-family: 'Raleway';
    font-size: 14px;

    tr {
        align-items: center;
        gap: 12px;
        align-self: stretch;
    }

    th {
        color: var(--vats-menu-text, #73726f);
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
    td {
        padding: 8px;
    }
    .left-cell {
        border-right: 1px solid #eaecf0;
    }
    .right-cell {
        border-left: 1px solid #eaecf0;
    }
    .cell {
        border-left: 1px solid #eaecf0;
        border-right: 1px solid #eaecf0;

    }
}

.redirect-cell {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    overflow: hidden;
    color: $color-main-text;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.text-medium {
    overflow: hidden;
    color: $color-main-text;
    text-overflow: ellipsis;
    font-weight: 600;
}
.text-normal {
    overflow: hidden;
    color: $color-main-text;
    text-overflow: ellipsis;
    font-weight: 500;
}

.text-inactive {
    overflow: hidden;
    color: $color-menu-icon;
    text-overflow: ellipsis;
    font-weight: 500;
}

.table-filter {
    display: flex;
    padding: 0px 8px;
    padding-top: 14px;
    align-items: center;
    gap: 6px;
    height: 28px;
    margin-bottom: 26px;
}

.slices-container {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e9e9e7;
    color: $color-menu-text;
    justify-content: flex-start;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    .slice {
        border-bottom: 1px solid #e9e9e7;
        display: flex;
        padding: 10px 8px;
        align-items: center;
        gap: 6px;
        cursor: pointer;
    }
    .active-slice {
        border-bottom: 2px solid var(--vats-medium-dark, #55534f);
        color: var(--vats-main-text, #37352f);
    }
    .free-slice {
        border-bottom: 1px solid #e9e9e7;
    }
    .slice-row {
        justify-content: space-between;
    }
}

.alignCenter {
    display: flex;
    align-items: center;
}

.sortable-head {
    cursor: pointer;
    align-items: center;
    gap: 4px;
}

.text-input {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    gap: 2px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    height: 28px;
    cursor: text;

    color: var(--vats-main-text, #37352f);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */

    &-active {
        color: $color-main-text;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */

        border: 1px solid $color-gray-400;
        background: var(--white, #fff);
        box-shadow: 0px 8px 18px -2px rgba(16, 24, 40, 0.12);
    }

    &-default {
        color: $color-menu-text;
    }
}
input.text-input-active:hover {
    border: 1px solid $color-gray-400;
}

.text-input-hover:hover {
    background: $color-input-bg-gray;
}

.line-selector {
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    cursor: text;
    padding: 0px 12px;

    border-radius: 10px;

    color: var(--vats-medium-dark, #55534f);
    font-variant-numeric: lining-nums proportional-nums;
    /* Text lg/Text xs/Semibold */
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */

    &-active {
        color: var(--vats-main-text, #37352f);
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Raleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */

        border: 1px solid var(--vats-grey-400, #dfdfde);
        background: var(--white, #fff);
        box-shadow: 0px 8px 18px -2px rgba(16, 24, 40, 0.12);
    }
    option {
        color: var(--vats-main-text, #37352f);
        font-variant-numeric: lining-nums proportional-nums !important;
        font-family: Raleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
    }

    &-default {
        color: $color-menu-text;
    }
}
input.line-selector-active:hover {
    border: 1px solid var(--vats-grey-400, #dfdfde);
}

.line-selector-hover:hover {
    background: var(--vats-input-bg-gray, #f7f7f5);
}

.line-status {
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;

    color: var(--vats-medium-dark, #55534f);
    font-variant-numeric: lining-nums proportional-nums;

    /* Text lg/Text xs/Semibold */
    font-family: Raleway !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 18px !important; /* 150% */
}

.task-container {
    top: 0;
    right: 0;

    .task-body {
        overflow-y: scroll;
        background-color: #fff;
        box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);

        .top-block {
            color: $color-menu-icon;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 24px;
            border-bottom: 1px solid $color-gray-400;
            padding: 12px 32px 12px 46px;
        }

        .task-content {
            padding: 0 32px 0 46px;
            
            .task-attr {
                border-bottom: 1px solid $color-gray-400;
                margin-bottom: 20px;

                .attr-label {
                    font-size: 12px;
                    font-weight: 600;
                    color: $color-menu-text;
                    padding-top: 4px;
                }

                .task-status-input {
                    max-width: 150px;
                }

                .reaction-by {
                    color: $color-menu-icon;
                    font-size: 12px;
                    font-weight: 600;
                    padding-top: 4px;
                }

                .task-members-list {
                    font-size: 12px;
                    font-weight: 600;
                    color: $color-main-text;

                    .member-list-item {
                        display: inline-block;
                        margin-right: 10px;
                    }
                }

                .company-edit {
                    .company-name {
                        font-size: 12px;
                        font-weight: 600;
                        color: $color-main-text;
                    }
                }
            }

            .task-description {
                padding-bottom: 6px;
                border-bottom: #D9D9D9 1px solid;
                position: relative;
                white-space: pre-wrap;

                .collapsed {
                    max-height: 70px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .text-detail {
                    color: $color-main-text;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                }
                .q {
                    position: absolute;
                    bottom: 25px;
                    left: 0;
                    right: 0;
                    height: 30px;
                }
                .grad {
                    background-image: linear-gradient(#ffffff82, #ffffffd9, #ffffff);
                }

                .show-more {
                    cursor: pointer;
                    color: #0086C9;
                    font-size: 12px;
                    font-weight: 500;
                    margin-top: 15px;
                }
            }


        }

        .messages-container {

            .messages-list {
                padding-left: 46px;
                padding-right: 32px;
                padding-bottom: 90px;

                .message-box {
                    padding-bottom: 20px;
                    padding-left: 8px;

                    .message-author {
                        color: $color-main-text;
                        font-size: 14px;
                        font-weight: 700;
                        padding-bottom: 4px;

                        .message-time {
                            color: $color-menu-icon;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }

                    .message-text {
                        color: $color-main-text;
                        font-size: 13px;
                        font-weight: 500;
                        white-space: pre-wrap;
                    }
                }
            }

            .messages-footer {
                border-top: #D9D9D9 1px solid;
                padding: 12px 40px 12px 54px;
                position: fixed;
                right: 0;
                bottom: 0;
                background-color: #fff;

                .btn-send-message {
                    position: absolute;
                    right: 51px;
                    top: 21px;
                }
            }
        }
    }
}

.holding-value {
    border-radius: 4px;
    background: $color-blue-gray-50;
    padding: 2px 8px;
    color: $color-blue-gray-700;
    font-size: 14px;
    font-weight: 600;
}

.gray-dot {
    border-radius: 4px;
    border: 1.5px solid #FFF;
    background: $color-gray-500;
    width: 8px;
    height: 8px;
    display: inline-block;
}

.green-dot {
    border-radius: 4px;
    border: 1.5px solid #FFF;
    background: $color-success-500;
    width: 8px;
    height: 8px;
    display: inline-block;
}

.orange-dot {
    border-radius: 4px;
    border: 1.5px solid #FFF;
    background: $color-warning-500;
    width: 8px;
    height: 8px;
    display: inline-block;
}

.justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}

.input-dropdown {
    position: absolute;
}

.task-row-status {
    display: flex;
    height: 24px;
    padding: 0px 4px;
    align-items: center;
    gap: 6px;
    align-self: stretch;    
}

.flex-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.input-add-icon {
    position: absolute;
    border: 1px solid #DFDFDE;
    padding: 6px;
    top: 31px;
    right: 0;
    border-radius: 5px;
    cursor: pointer;
}

.archive-row {
    background-color: $color-gray-200;
}

.top-26 {
    top: 26px !important;
}

.av-card {
    padding: 24px;
    height: 134px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    }

    .title-img-block {
        width: 40px;
        height: 40px;
        border-radius: 23px;
    }
}

.input-hint {
    position: absolute;
    background-color: #FFF;
    font-size: 11px;
    width: 100%;
    border: 1px solid $color-gray-400;
    padding: 0 10px;
    border-radius: 0 0 5px 5px;
    border-top: none;
    z-index: 100;
}

.btn-outline-dark{
    &:hover {
        img {
            filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(266deg) brightness(103%) contrast(103%) !important;
        
        }
    }
}

.modal {
    color: #37352f !important;

    .modal-content {
        .modal-body {
            font-size: 16px !important;
            font-weight: 400 !important;
        }
    }
}

.inpit-letters-count {
    position: absolute;
    right: 15px;
    bottom: 5px;
    font-size: 12px;
}

.apexcharts-menu {
    width: 115px !important;
  }
@import '_colors';

.notifications {

    &-badge {
        display: flex;
        padding: 4px 10px 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 16px;
        border: 1px solid $color-menu-icon;
    }

    &-green {
        background: #D1FADF !important;        
        border: 1px solid #A6F4C5 !important;
        color: #027A48 !important;
    }

    &-badge-text {
        color: $color-menu-text;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }

}

.notifications-container {
    top: 0;
    right: 0;
    padding-left: 0;
    padding-right: 0;

    z-index: 100;
    height: calc(100vh - 19px) !important;

    display: flex;
    width: 652px;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 0px 8px 8px 0px;
    border-left: 1px solid #EAECF0;
    background: var(--White, #FFF);



    .notifications-header {
        display: flex;
        height: 64px;
        padding: 10px 70px 10px 16px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-bottom: 1px solid #D9D9D9; 
        justify-content: space-between;
  
        
        color: $color-main-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */

        .caption {
            display: flex;
            align-items: center;
            gap: 6px;
        }
        .close-button {
            border-radius: 16px;
            border: 1px solid #DFDFDE;
            background: #FFF;
            color: #73726F;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */   
            padding: 4px 10px 4px 12px;     
        }
    }


    .notifications-body {
        //overflow-y: auto;
        background: #fff;
        width: 100%;



        .notifications-row {
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;

            border-bottom: 1px solid #EAECF0;
            border-left: 1px solid #EAECF0;
            //background: var(--success-25, #F6FEF9);

            &-green {
                background: #F6FEF9;
            }

            .author {
                color: $color-main-text;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */            
            }
            .subject {
                color: $color-main-text;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */            
            }
            .action {
                color: $color-menu-text;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;                
            }
            .date {
                color: $color-menu-text;
                font-variant-numeric: lining-nums proportional-nums;
                font-feature-settings: 'ss01' on, 'salt' on;
                text-overflow: ellipsis;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 166.667% */                
            }
            .comments-header {
                display: flex;
                align-items: center;
                gap: 6px;

                color: $color-menu-text;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 166.667% */
            }
            .comments-text {
                color: $color-main-text;
                font-feature-settings: 'ss01' on, 'salt' on;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */                
            }
            .separator {
                height: 12px;
            }
            .indent {
                margin-left: 32px;
            }
            .close {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}
.page-size-block {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 30px;
    color: $color-main-text;
    font-variant-numeric: lining-nums proportional-nums;
    justify-content: flex-end;
    
    /* Text sm/Medium */
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    .caption {
        color: $color-main-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */        
    }

    .page-size-numbers {
        display: flex;
        align-items: center;
        cursor: pointer;
        .active {
            background: #EBEBEA !important;
        }

        .page-button {
            color: $color-main-text;
            font-variant-numeric: lining-nums proportional-nums;
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            background: #FFF;

            display: flex;
            min-width: 40px;
            height: 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;            
            padding: 5px 8px;
            border: 1px solid #DFDFDE;
        }
        .left {
            border-radius: 4px 0px 0px 4px;
        }
        .right {
            border-radius: 0px 4px 4px 0px;
        }

        button.page-button:hover {
            background: var(--vats-input-bg-gray, #F7F7F5) !important;
        }
    
    }
}

.cx-page-count-info {
    color: #73726F;
    text-align: center;
    font-feature-settings: lining-nums proportional-nums;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}


.paginator {
    font-family: 'Raleway' !important;
    font-variant-numeric: lining-nums proportional-nums !important;
    font-weight: 500;
    color: $color-main-text;

}

.cx-pagination {
    list-style: none;
    display: flex;
    padding-left: 0;
    font-feature-settings: lining-nums proportional-nums;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    .active {
        //border-top: 1px solid var(--vats-grey-400, #DFDFDE) !important;
        //border-right: 1px solid var(--vats-grey-400, #DFDFDE) !important;
        //border-bottom: 1px solid var(--vats-grey-400, #DFDFDE) !important;
        background: var(--vats-menu-selected-bg, #EBEBEA) !important;    
    }
    .page-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--White, #FFF) !important;  
        min-width: 40px;  
        height: 30px;
        padding: 0px 8px;

        border-top: 1px solid var(--vats-grey-400, #DFDFDE);
        border-right: 1px solid var(--vats-grey-400, #DFDFDE);
        border-bottom: 1px solid var(--vats-grey-400, #DFDFDE);


    }  
    li.page-item:hover {
        background: var(--vats-input-bg-gray, #F7F7F5) !important;
    }

    .page-item-left {
        border-left: 1px solid var(--vats-grey-400, #DFDFDE);
        padding: 10px 16px;
        gap: 8px;        
        border-radius: 4px 0px 0px 4px !important;
        min-width: 100px;
    }  
    .page-item-right {
        padding: 10px 16px;
        gap: 8px;   
        border-radius: 0px 4px 4px 0px;     
        min-width: 100px;
    } 
    .page-link {
        z-index: 3;        
    } 
    .active{
        background: var(--vats-menu-selected-bg, #EBEBEA) !important;
    }
    .disabled {
        color: var(--vats-menu-icon, #A2A19F);
    }
}

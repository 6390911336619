@import '_colors';

@for $i from 3 through 7 {
    .w-#{$i}00 { 
        font-weight: #{$i}00 !important;
    }
  }

.text-gray {
    color: $color-gray;
}

.text-gray-main {
    color: $color-gray-main;
}

.rotate-180 {
    transform: rotate(180deg);
}
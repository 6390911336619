@import '_colors';

.button {
    display: flex;
    height: 30px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 

    &-blue {
        border-radius: 4px;
        color: #fff;
        border: 1px solid $color-blue-light-700;
        background: $color-blue-light-600;

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &:disabled {
            background: #fff;
            color: $color-menu-text;
            border: 1px solid $color-menu-icon;

        }
    }
    &-white {
        border-radius: 4px;
        color: $color-menu-text;
        border: 1px solid $color-menu-text;
        background: #fff;

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

}
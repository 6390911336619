@import '_colors';

.circle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    border: 1.5px solid #FFF;
    background: $color-gray-300;

    &-green {
        background: $color-success-500;    
    }
}

@import '_colors';

.dashboard {

    &-list {
        display: flex;
        max-width: 431px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;        
    }


    &-card {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: center;
        gap: 17px;
        align-self: stretch;
        border-radius: 4px;
        background: #F6F5F4;
    }

    &-header {

        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        color: $color-main-text;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 155.556% */        
    }
    &-values {
        display: flex;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;

        .variable-value {
            color: #101828;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 38px; /* 126.667% */
        }

        .variable-name {
            color: $color-main-text;
            font-feature-settings: 'ss01' on, 'salt' on;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */            
        }
        .missed {
            color: #F04438;
        }
    }
}
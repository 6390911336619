// иконки берем черные и выбираем цвета на https://change-svg-color.vercel.app/

.icon-main {
    filter: invert(20%) sepia(10%) saturate(437%) hue-rotate(8deg) brightness(94%) contrast(96%);
}

.icon-medium-dark {
    filter: invert(31%) sepia(7%) saturate(289%) hue-rotate(2deg) brightness(95%) contrast(89%);
}

// рыжий цвет активного фильтра
.icon-active {
    filter: invert(29%) sepia(73%) saturate(1239%) hue-rotate(353deg) brightness(96%) contrast(102%);
}

// серая неактивная иконка
.icon-gray {
    filter: invert(45%) sepia(1%) saturate(2378%) hue-rotate(6deg) brightness(96%) contrast(81%);
}

// черная иконка активная
.icon-black {
    filter: invert(29%) sepia(7%) saturate(291%) hue-rotate(2deg) brightness(103%) contrast(90%);
}

.icon-white {
    filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(266deg) brightness(103%) contrast(103%);
}

// навигационное меню слева
.icon-nav {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(1deg) brightness(106%) contrast(101%);
    opacity: 0.85;
}

// зеленый цвет направления звонка
.icon-green {
    filter: invert(61%) sepia(57%) saturate(4967%) hue-rotate(118deg) brightness(98%) contrast(86%);
}

// красный цвет направления звонка
.icon-red {
    filter: invert(58%) sepia(74%) saturate(7172%) hue-rotate(343deg) brightness(99%) contrast(89%);
}


// статус - не выбрано 
.icon-status-none {
    filter: invert(71%) sepia(3%) saturate(1279%) hue-rotate(179deg) brightness(90%) contrast(90%);
}

// статус - черновик 
.icon-status-draft {
    filter: invert(44%) sepia(7%) saturate(143%) hue-rotate(7deg) brightness(98%) contrast(84%);
}

// статус - отложено 
.icon-status-postponed {
    filter: invert(35%) sepia(12%) saturate(2747%) hue-rotate(194deg) brightness(96%) contrast(85%);
}

// статус -важно 
.icon-status-important {
    filter: invert(60%) sepia(86%) saturate(5001%) hue-rotate(334deg) brightness(90%) contrast(109%);
}

// статус -выполнено 
.icon-status-complete {
    filter: invert(71%) sepia(49%) saturate(5452%) hue-rotate(110deg) brightness(91%) contrast(86%);
}

// статус - в планах
.icon-status-plan {
    filter: invert(52%) sepia(43%) saturate(6026%) hue-rotate(291deg) brightness(95%) contrast(96%);
}

// статус - подготовка
.icon-status-prepare {
    filter: invert(34%) sepia(77%) saturate(3141%) hue-rotate(237deg) brightness(103%) contrast(94%);
}

// статус - к выполнению
.icon-status-execute {
    filter: invert(58%) sepia(87%) saturate(1433%) hue-rotate(343deg) brightness(99%) contrast(93%);
}

// иконка колокольчика
.icon-success-500 {
    filter: invert(56%) sepia(46%) saturate(3043%) hue-rotate(113deg) brightness(94%) contrast(86%);
}

.opacity-75 {
    opacity: 0.75 !important;
}
.opacity-95 {
    opacity: 0.95 !important;
}
@import '_colors';

.btn-primary {
    background: $color-blue-light-600;
}

.btn-main {
    background: $color-gray-main;
    color: #fff;

    &:hover {
        background-color: #000;
        color: #fff;
    }

    &:active {
        background-color: #000 !important;
        color: #fff !important;
    }
}

.cx {
    .btn {
        font-weight: 700;
        padding: 10px 18px;
    }
    .form-label {
        font-family: 'Raleway';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .form-control {
        font-variant-numeric: lining-nums proportional-nums;
        font-weight: 500;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        border: 1px solid $color-gray-400;
    }
}
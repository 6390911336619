@import '_colors';

.dropdown {
    display: flex;
    padding: 8px 0px 6px 0px;
    flex-direction: column;
    align-items: center;
    gap: 6px !important;
    align-self: stretch;

    overflow-y: auto;

    border-radius: 4px;
    border: 1px solid $color-gray-400;
    background: var(--white, #fff);
    box-shadow: 0px 14px 18px -2px rgba(16, 24, 40, 0.12);

    color: $color-medium-dark;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */

    &-input {
        display: flex;
        padding: 0px 16px;
        align-items: center;
        flex-direction: column;
        gap: 6px;
        align-self: stretch;
    }

    &-row {
        display: flex;
        padding: 0px 16px;
        align-items: center;
        gap: 6px;
        align-self: stretch;
    }

    &-check {
        display: flex;
        padding: 5px 14px;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
        align-self: stretch;
        overflow: hidden;
    }

    &-empty {
        color: $color-menu-text;
        width: 100%;
        padding: 0px 12px;
    }

    &-label {
        width: 100%;
        padding: 0px 12px;
    }

    // выравнивание с начала строки
    &-flex-start {
        display: flex;
        gap: 6px;
        justify-content: flex-start;
        align-items: center;
        padding: 4px 10px 4px 12px;
    }

    &-scroll {
        overflow-y: auto;
        width: 100%;
    }
    input {
        display: flex;
        padding: 6px 16px;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        align-self: stretch;

        color: $color-menu-icon;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */

        border-radius: 4px;
        border: 1px solid $color-gray-400 !important;
        background: $color-menu-bg;
    }
    input:focus {
        color: $color-main-text;
    }
}

.cx-dropdown {
    &-input {
        border-radius: 4px;
        padding: 6px 8px 6px 12px;
        font-size: 12px;
        font-weight: 600;
    }
}

@import '_colors';

.entity-card {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    color: $color-main-text;

    .property {
        display: flex;
        align-items: center;
        gap: 6px;
        padding-top: 4px;
        padding-bottom: 4px;

        &-title {
            color: $color-menu-text;
        }

        &-value {
            color: $color-main-text;
            position: relative;
        }
        &-value:hover {
            background: $color-input-bg-gray;    
            cursor: text;
        }

        &-header {
            margin-left: 12px;
            padding: 20px 8px 10px 0px;
            border-radius: 4px 4px 0px 0px;
            border-bottom: 1px solid #E9E9E7;
        
            color: $color-main-text;
            font-variant-numeric: lining-nums proportional-nums;
        }

        .form-switch {
            padding-left: 48px;
        }
    }
}
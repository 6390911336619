$color-yellow: #f78a07;
$color-yellow-main: #f5e3cb;

$color-pink-main: #e38e8b;

$color-primary: #052f56;


$color-blue-gray-50: #F8F9FC;
$color-blue-gray-700: #363F72;
$color-blue-light-600: #0086C9; 
$color-blue-light-700: #026AA2;

$color-success-500: #12B76A;
$color-success-600: #039855;

$color-gray: #73726F;
$color-gray-main: #37352F;
$color-gray-200: #EAECF0;
$color-gray-300: #D0D5DD;
$color-gray-400: #DFDFDE;
$color-gray-500: #667085;

$color-medium-dark: #55534F;

$color-menu-text: #73726F;
$color-main-text: #37352F;

$color-menu-bg: #FBFBFA;

$color-menu-icon: #A2A19F;

$color-input-bg-gray: #F7F7F5;

$color-filters-active-border: #EFD074;
$color-filters-active-background: #FFECB9;
$color-filters-active-text: #B54708;

$color-warning-500: #F79009